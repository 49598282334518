import { differenceInDays, format, isThisWeek, isThisYear, isToday, isYesterday } from "date-fns";

export function getDateDifferenceInDays(firstDate: Date, secondDate: Date) {
  const millisecondsInDay = 1000 * 3600 * 24;
  const diffTime = secondDate.getTime() - firstDate.getTime();
  const diffDays = Math.floor(diffTime / millisecondsInDay);
  return diffDays;
}

/**
 * functionality to get date for group of logs
 *
 * @returns {string}
 */
export function formatRelativeDateForHistory(
  groupDate: number | string
): string {
  const date = new Date(groupDate);
  const isCurrentWeek = isThisWeek(date);

  if (isCurrentWeek) {
    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    }
    return format(date, "EEEE");
  }
  const isCurrentYear = isThisYear(date);
  if (isCurrentYear) {
    return format(date, "MMMM d");
  }

  return format(date, "MMMM d, Y");
}

export const isDateLaterThanDaysAgo = (
  dateTimestamp: number,
  daysAgo: number
) => {
  const daysDifference = differenceInDays(new Date(), new Date(dateTimestamp));
  return daysDifference >= daysAgo;
};
