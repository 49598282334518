import { SearchFileTypes, SearchType } from "@/src/type/blaceV2";

type SearchItemFilesManagerType = {
  setNewFile: (
      searchItemFiles: SearchType.SearchFile[],
      setListingItemFiles: (files: SearchType.SearchFile[]) => void,
      newListingItemFile: SearchType.SearchFile | undefined,
      searchItemFileTypeToRemove?: SearchFileTypes
  ) => void;
  getFile: (
      searchItemFiles?: SearchType.SearchFile[],
      fileType?: SearchFileTypes
  ) => SearchType.SearchFile | undefined;
};

export function useSearchItemFilesManager(): SearchItemFilesManagerType {
  return {
    setNewFile: (
        searchItemFiles: SearchType.SearchFile[],
        setListingItemFiles: (files: SearchType.SearchFile[]) => void,
        newListingItemFile: SearchType.SearchFile | undefined,
        searchItemFileTypeToRemove?: SearchFileTypes
    ): void => {
      if (!setListingItemFiles) {
        return;
      }

      if (!searchItemFiles.length) {
        if (!newListingItemFile) {
          return;
        }

        const files: SearchType.SearchFile[] = [];

        files.push(newListingItemFile);
        setListingItemFiles(files);

        return;
      }

      if (!newListingItemFile && !searchItemFileTypeToRemove) {
        // it's unclear what to remove
        return;
      }

      const files: SearchType.SearchFile[] = [...searchItemFiles];

      const fileTypeExistIndex = files.findIndex(
          file => file.type === (newListingItemFile?.type ?? searchItemFileTypeToRemove)
      );
      if (newListingItemFile) {
        if (fileTypeExistIndex !== -1) {
          files[fileTypeExistIndex] = newListingItemFile;
        } else {
          files.push(newListingItemFile);
        }
      } else if (fileTypeExistIndex !== -1) {
        files.splice(fileTypeExistIndex, 1);
      } else {
        // the type of file to remove not exists
      }
      setListingItemFiles(files);
    },

    getFile: (searchItemFiles, fileType): SearchType.SearchFile | undefined => {
      if (!searchItemFiles?.length || !fileType) {
        return undefined;
      }

      const fileTypeExistIndex = searchItemFiles.findIndex(
          file => file.type === fileType
      );

      return fileTypeExistIndex === -1
          ? undefined
          : searchItemFiles[fileTypeExistIndex];
    },
  };
}
