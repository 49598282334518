import { useEffect, useState } from "react";
import type { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import { useSmallHeighLargeWidth } from "@/src/hook";
import styles from "./DetailSectionInfo.module.scss";

export interface DetailSectionInfoBodyProps {
  tag: "h2" | "h3" | "h4" | "body1";
  elementType?: any;
  strikeThrough?: boolean;
  className?: string;
}

// eslint-disable-next-line react/display-name
function DetailSectionInfoBody({
  tag,
  elementType,
  strikeThrough,
  className,
  children,
}: PropsWithChildren<DetailSectionInfoBodyProps>) {
  const [smallHeight, setSmallHeight] = useState<boolean>(false);
  const heightLimit = useSmallHeighLargeWidth();

  useEffect(() => {
    setSmallHeight(heightLimit);
  }, [heightLimit]);

  return (
    <Typography
      variant={tag}
      color="common.black"
      fontWeight="400"
      lineHeight={smallHeight ? "21.4px" : "22.4px"}
      fontSize={smallHeight ? "14px" : "16px"}
      className={cn(className, styles.childrenText, {[styles.lineThrough]: strikeThrough})}
      component={elementType}
    >
      {children}
    </Typography>
  );
}

export default DetailSectionInfoBody;
