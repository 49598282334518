import { ChangeEvent, forwardRef, LegacyRef, useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useInquiryForm } from "@/src/hook";
import { AuthLogic, SearchLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { LoginResponseProfile } from "@/src/type/blaceV1/AuthType";
import { InquiryTypes, SearchType } from "@/src/type/blaceV2";
import { FormHelper } from "@/src/util";
import { StringHelper } from "@/src/util/helper";
import {
  BaseButton,
  DatePicker,
  Dropdown,
  TextFieldHelperText,
} from "@src/component/base";
import type { InquiryFormTypeFormik } from "@/src/hook";
import styles from "./InquiryForm.module.scss";
import InquiryFormFooter from "./InquiryFormFooter";
import InquiryFormHeader, { InquiryFormHeaderProps } from "./InquiryFormHeader";

interface InquiryFormProps extends InquiryFormHeaderProps {
  slug?: string;
  altCatalogId?: string;
  catalogId?: string;
  searchId?: string;
  inquiryType?: BlaceV2Type.EventType.InquiryType;
  maxGuestCount: number;
  sendInquiryDialogClose?: () => void;
  setInquiryLimitReached: () => void;
  inquiryFormData?: BlaceV2Type.EventType.InquiryFormDataProps;
  listing?: SearchType.Search;
}

function InquiryForm({
  isExclusive,
  primaryContact,
  pricing,
  hideStartingAt,
  altStartingAt,
  slug,
  altCatalogId,
  inquiryType,
  catalogId,
  searchId,
  maxGuestCount,
  inquiryFormData,
  listing,
  sendInquiryDialogClose,
  setInquiryLimitReached,
}: InquiryFormProps, ref: LegacyRef<HTMLDivElement>) {
  const email = AuthLogic.listenAuthCurrentUser()?.email;
  const [currentUser, setCurrentUser] = useState<LoginResponseProfile | undefined>(undefined);
  const [emailAtInit, setEmailAtInit] = useState<string | undefined>(email);
  const [showClientName, setShowClientName] = useState<boolean>(true);
  
  const inquiryForm = useInquiryForm({
    inquiryType: inquiryType ?? InquiryTypes.Venue,
    slug,
    altCatalogId,
    catalogId,
    searchId,
    maxGuestCount,
    inquiryFormData,
    sendInquiryDialogClose,
    setInquiryLimitReached,
  });

  useEffect(() => {
    setCurrentUser(AuthLogic.getAuthCurrentUser());
  }, []);

  useEffect(() => {
    if (AuthLogic.getIdentityToken()) {
      const thisEmail = currentUser?.email;
      setEmailAtInit(thisEmail);
    }
  }, [currentUser?.email]);

  useEffect(() => {
    if (emailAtInit) {
      if (emailAtInit !== inquiryForm.formik.values.email) {
        const name = currentUser?.name;
        const hasName: boolean = (name ?? "").length > 2;
        inquiryForm.formik.setFieldValue("email", emailAtInit, !hasName);
        if (hasName) {
          inquiryForm.formik.setFieldValue("clientName", name, false);
          setShowClientName(false);
        }
      }
    }
  }, [currentUser?.name, emailAtInit, inquiryForm]);

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    const formattedNumber = StringHelper.formatPhoneNumber(input);
    inquiryForm.formik.setFieldValue("phone", formattedNumber ?? "");
  };

  return (
    <div className={styles.inquiryForm} ref={ref}>
      {inquiryForm.isLoading && (
        <div className={styles.inquirySubmitted}>
          <CircularProgress size={80} color="secondary" />
        </div>
      )}
      <InquiryFormHeader
        isExclusive={isExclusive}
        primaryContact={primaryContact}
        pricing={pricing}
        hideStartingAt={hideStartingAt}
        altStartingAt={altStartingAt}
      />
      <form
        className={styles.inquiryFormContainer}
        onSubmit={inquiryForm.formik.handleSubmit}
      >
        {!inquiryFormData ? (
          <TextField
            label="Event name"
            placeholder="Holiday Cocktail Party"
            fullWidth
            onChange={(e) => {
              inquiryForm.formik.setFieldValue(
                "eventName",
                e?.target?.value ?? ""
              );
            }}
            value={inquiryForm.formik.values.eventName}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "eventName"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "eventName"
            )}
          />
        ) : null}
        {currentUser && (
          <TextField
            label="Phone number"
            placeholder="+1 (555) 555-5555"
            fullWidth
            onChange={handlePhoneChange}
            value={inquiryForm.formik.values.phone}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "phone"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "phone"
            )}
          />
        )}
        <div className={styles.inquiryFormFormSplit}>
          <DatePicker
            //open={startDateOpen}
            label="Start date"
            views={["year", "month", "day"]}
            yearsPerRow={3}
            disablePast={true}
            maxDate={new Date().setFullYear(new Date().getFullYear() + 2)}
            value={inquiryForm.formik.values.startDate}
            onChange={(val) => {
              inquiryForm.formik.setFieldValue("startDate", val);
            }}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "startDate"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "startDate"
            )}
          />
          <DatePicker
            label="End date"
            views={["year", "month", "day"]}
            yearsPerRow={3}
            disablePast={true}
            maxDate={new Date().setFullYear(new Date().getFullYear() + 2)}
            value={inquiryForm.formik.values.endDate}
            onChange={(val) => {
              inquiryForm.formik.setFieldValue("endDate", val);
            }}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "endDate"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "endDate"
            )}
          />
        </div>
        <FormControlLabel
          className={styles.inquiryFormCheckbox}
          label={<Typography variant="body2">My dates are flexible</Typography>}
          control={
            <Checkbox
              value={inquiryForm.formik.values.flexibleDates}
              checked={inquiryForm.formik.values.flexibleDates}
              onChange={() => {
                inquiryForm.formik.setFieldValue(
                  "flexibleDates",
                  !inquiryForm.formik.values.flexibleDates
                );
              }}
            />
          }
        />
        <div className={styles.inquiryFormFormSplit}>
          <Dropdown
            id={"InquiryForm.Budget"}
            label="Budget"
            items={[
              { value: "1000-5000", label: "$1,000 - $5,000" },
              { value: "5000-20000", label: "$5,001 - $20,000" },
              { value: "20000-50000", label: "$20,001 - $50,000" },
              { value: "50000-100000", label: "$50,001 - $100,000" },
              { value: "100000+", label: "Over $100,000" },
              { value: "unknown", label: "I don't know yet" },
            ]}
            onChange={(e) => {
              inquiryForm.formik.setFieldValue(
                "budget",
                e?.target?.value ?? ""
              );
            }}
            value={inquiryForm.formik.values.budget}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "budget"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "budget"
            )}
          />
          <TextField
            label="Guest count"
            fullWidth
            type="number"
            onChange={(e) => {
              inquiryForm.formik.setFieldValue(
                "guests",
                e?.target?.value ?? ""
              );
            }}
            value={inquiryForm.formik.values.guests}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "guests"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "guests"
            )}
            inputProps={{ inputMode: "numeric" }}
          />
        </div>
        {showClientName && (
          <TextField
            label="Full Name"
            placeholder="Full Name"
            fullWidth
            onChange={(e) => {
              inquiryForm.formik.setFieldValue(
                "clientName",
                e?.target?.value ?? ""
              );
            }}
            value={inquiryForm.formik.values.clientName ?? ""}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "clientName"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "clientName"
            )}
          />
        )}
        {!currentUser && (
          <TextField
            label="Phone number"
            placeholder="+1 (555) 555-5555"
            fullWidth
            onChange={handlePhoneChange}
            value={inquiryForm.formik.values.phone}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "phone"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "phone"
            )}
          />
        )}
        {!emailAtInit && (
          <TextField
            label="Contact Email"
            placeholder="The best email to reach you"
            fullWidth
            onChange={(e) => {
              inquiryForm.formik.setFieldValue("email", e?.target?.value ?? "");
            }}
            value={inquiryForm.formik.values.email ?? ""}
            helperText={FormHelper.formikErrorMessage<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "email"
            )}
            error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
              inquiryForm.formik,
              "email"
            )}
          />
        )}
        <TextField
          label="Tell us about your event"
          placeholder="Include what type of event you are planning and how we can help make your event special"
          fullWidth
          multiline={true}
          rows={4}
          onChange={(e) => {
            inquiryForm.formik.setFieldValue("notes", e?.target?.value ?? "");
          }}
          value={inquiryForm.formik.values.notes}
          helperText={
            <TextFieldHelperText
              value={inquiryForm.formik.values.notes}
              minLength={22}
              maxLength={3999}
            />
          }
          error={FormHelper.formikCheckError<InquiryFormTypeFormik>(
            inquiryForm.formik,
            "notes"
          )}
        />
        <BaseButton 
          type="submit" 
          variant="contained" 
          color="secondary" 
          disabled={!SearchLogic.isSearchAvailableForInquiries(listing, currentUser)}
        >
          Contact Us
        </BaseButton>
      </form>
      <InquiryFormFooter isExclusive={isExclusive} />
    </div>
  );
}

export default forwardRef(InquiryForm);
