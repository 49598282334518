//import dynamic from "next/dynamic";
import { ReactElement } from "react";
import { AdvertisingCard, ListingCardV2 } from "@/src/component/partial";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";

//const ListingCard = dynamic(() => import("@/src/component/partial/ListingCard/ListingCard"), { ssr: false, });

type AdCardPlacement = {
  placement: number;
};

type ResultsToCard = {
  items: BlaceV2Type.SearchType.SearchItem[];
  viewVariant: keyof typeof ListingDisplayVariant;
  ads?: AdCardPlacement[];
  alwaysEager?: boolean;
  inquiryId?: string;
  onCardHover?: (cardSlug?: string) => void;
};

export function resultsToCards({
  items,
  viewVariant,
  ads,
  alwaysEager,
  inquiryId,
  onCardHover
}: ResultsToCard): ReactElement[] {
  const isAdvertisingCardSupported = false;

  const arr: ReactElement[] = [];
  let iter = 0;
  for (const item of items) {
    //todo: wire up to CMS
    if (Array.isArray(ads)) {
      for (const ad of ads ?? []) {
        if (ad.placement === iter && isAdvertisingCardSupported) {
          //first element is the ad container
          arr.push(
            <AdvertisingCard
              id="ad"
              viewVariant={viewVariant}
              placement={`${iter}`}
            />
          );
          //this element is an empty element for overlfow of ad on large screen
          arr.push(<div id="empty" />);
        }
      }
    }
    arr.push(
      <ListingCardV2
        item={item}
        eager={alwaysEager || viewVariant === "map" ? true : iter < 6}
        inquiryId={inquiryId}
        onHover={onCardHover}
      />
    );
    iter++;
  }
  return arr;
}
