import { ASSET_URL } from "@/src/const";

export function convertFileStorageToAssetUrl(initialUrl: string) {
  if (ASSET_URL && initialUrl.indexOf(ASSET_URL) !== -1) {
    return initialUrl;
  };

  const regex = /http(s)?:\/\/[\w.-]+\/assets(.+)/;
  
  if (!regex.test(initialUrl)) {
    return null;
  };
  
  const newUrlSubString = ASSET_URL;
  const autoParam = "format=auto";
  const filePath = initialUrl.split(regex)[2];
  const newUrl = newUrlSubString + filePath;
 
  if (initialUrl.includes("?") && !initialUrl.includes(autoParam)) {
    return newUrl + "&" + autoParam;
  };

  return newUrl + `${initialUrl.includes(autoParam) ? "" : "?" + autoParam}`;
}
