import { format, isToday, isTomorrow, isYesterday } from "date-fns";
import { Log } from "@/src/util";

export function formatExtendedDate(timestamp: number) {
  try {
    const date = new Date(timestamp);
    const formattedDate = format(date, "MMM d, yyyy");
    return formattedDate;
  } catch (err) {
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "FormattedDateHelper.ts",
      "formatExtendedDate / Date formatting error",
      [{ value: timestamp, err }]
    );
    return "";
  }
}

export function formatRelativeDate(timestamp: number) {
  try {
    const date = new Date(timestamp);
    const now = new Date(Date.now());

    if (isToday(date)) {
      const hoursAgo = Math.max(
        1,
        Math.floor((now.getTime() - date.getTime()) / (60 * 60 * 1000)) // UTC comparison
      );
      return hoursAgo === 1 ? "1 hour ago" : `${hoursAgo} hours ago`;
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else if (isTomorrow(date)) {
      return "Tomorrow";
    }
    return format(date, "MM/dd/yy");
  } catch (err) {
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "FormattedDateHelper.ts",
      "formatRelativeDate / Date formatting error",
      [{ value: timestamp, err }]
    );
    return "";
  }
}

export function formatGlobalDate(timestamp: number) {
  try {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  } catch (err) {
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "FormattedDateHelper.ts",
      "formatGlobalDate / Date formatting error",
      [{ value: timestamp, err }]
    );
    return "";
  }
}

export function formatTimestampToTime(timestamp: number) {
  try {
    const date = new Date(timestamp);
    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  } catch (err) {
    Log.logToDataDog(
      Log.LogLevel.ERROR,
      "FormattedDateHelper.ts",
      "formatTimestampToTime / Date formatting error",
      [{ value: timestamp, err }]
    );
    return "";
  }
}

/**
 * formats the given Date object to the system date format - `YYYY-MM-DD HH:MM:SS`
 *
 * @returns {string}
 */
export function formatSystemDate(
    inputDate: Date
): string {
  return format(inputDate, "yyyy-MM-dd HH:mm:ss");
}
