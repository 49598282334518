//https://www.npmjs.com/package/react-multi-carousel
//potential replacement for current library running CarouselImage if needed
import React, { ReactNode, useState } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Carousel from "react-material-ui-carousel";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import { ComponentType } from "@/src/type";
import styles from "./CarouselImage.module.scss";
import { CarouselImageItem } from "./CarouselImageItem";
interface CarouselImageProps extends CarouselProps {
  images: ComponentType.Carousel.ImageType[];
  autoPlay?: boolean;
  imageClassName: string;
  navButtonsAlwaysVisible?: boolean;
  indicatorAlwaysVisible?: boolean;
  imageCountNoIndicators?: number;
  animation?: "fade" | "slide";
  quality?: number;
  width?: number;
  index?: number;
  fixedImageSize?: boolean;
  imageOnClick?: (index?: number) => void;
  objectFit?: "contain" | "cover";
  NextIcon?: ReactNode;
  PrevIcon?: ReactNode;
  navButtonsClassName?: string;
  onImageChange?: (image: ComponentType.Carousel.ImageType) => void;
}

function CarouselImage({
  images,
  autoPlay,
  imageClassName,
  navButtonsAlwaysVisible = false,
  indicatorAlwaysVisible = false,
  imageCountNoIndicators = 12,
  animation = "fade",
  quality,
  width,
  index,
  fixedImageSize,
  objectFit,
  imageOnClick,
  NextIcon,
  PrevIcon,
  navButtonsClassName,
  onImageChange,
  ...carouselProps
}: CarouselImageProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(index ?? 0);

  function handleOnChange(now?: number) {
    setCurrentIndex(now ?? 0);
    onImageChange && onImageChange(images[now || 0]);
  }

  function handleOnImageItemClick() {
    if (typeof imageOnClick === "function") {
      imageOnClick(currentIndex);
    }
  }

  return (
    <Carousel
      className={fixedImageSize ? styles.carousel : undefined}
      index={index ?? 0}
      NextIcon={NextIcon || <NavigateNextIcon />}
      PrevIcon={PrevIcon || <NavigateBeforeIcon />}
      navButtonsProps={{
        className: navButtonsClassName || styles.carouselImageNavButton,
      }}
      swipe={true}
      stopAutoPlayOnHover={true}
      autoPlay={autoPlay ?? false}
      indicatorContainerProps={{
        className:
          (images ?? []).length > imageCountNoIndicators && !indicatorAlwaysVisible
            ? styles.listingCarouselDoNotShow
            : styles.listingCarouselIndicatorContainer,
      }}
      navButtonsAlwaysVisible={navButtonsAlwaysVisible}
      animation={animation}
      onChange={handleOnChange}
      {...carouselProps}
    >
      {(images ?? []).map((item, i) => (
        <CarouselImageItem
          key={i}
          url={item.url}
          id={item.url}
          imageClassName={imageClassName}
          quality={quality}
          width={width}
          fixedImageSize={fixedImageSize}
          objectFit={objectFit}
          imageOnClick={handleOnImageItemClick}
        />
      ))}
    </Carousel>
  );
}

export default CarouselImage;
