import { MouseEvent, useMemo } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Divider, Typography } from "@mui/material";
import cn from "classnames";
import Image from "next/image";
import { BaseIcon, ImgBgResponsive } from "@/src/component/base";
import { CarouselImage } from "@/src/component/partial/CarouselImage";
import { KEYS } from "@/src/const";
import { useBreakPointDown } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type, ComponentType } from "@/src/type";
import { NumberHelper, SharedConfigManager } from "@/src/util";
import styles from "./ListingCardV2.module.scss";

interface ListingCardInnerV2Props {
  item: BlaceV2Type.SearchType.SearchItem;
  color: string;
  location: BlaceV2Type.SearchType.SearchLocation;
  showCard: boolean;
  isMobile: boolean;
  mainImageUrl: string;
  isMapPreview?: boolean;
  setMainImageUrl: (image: string) => void;
}

const pinPreviewCarouselHeight = 190;
const listingCardCarouselHeight = 245;

function ListingCardInnerV2({
  item,
  color,
  location,
  showCard,
  mainImageUrl,
  isMapPreview,
  setMainImageUrl,
}: ListingCardInnerV2Props) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const images: ComponentType.Carousel.ImageType[] = useMemo(
    () => SearchLogic.formatCarouselImages(item),
    [item],
  );
  const isMobile = useBreakPointDown("md");

  const renderExclusiveTag = () =>
    item.facts?.isExclusive && (
      <Typography
        color={color}
        variant="subtitle2"
        component="p"
        className={cn(styles.listCardExclusive, styles.listCardImgContent)}
      >
        BLACE Exclusive
      </Typography>
    );

  const preventGoToDetailPage = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div className={styles.listingInner}>
      <div className={styles.listingMainImg}>
        <ImgBgResponsive
          className={cn(styles.listCardImgContainer, { [styles.inMapPreview]: isMapPreview })}
          imageUrl={mainImageUrl}
          lazy={showCard ? "eager" : "lazy"}
          prefetch={false}
        >
          {renderExclusiveTag()}
        </ImgBgResponsive>
      </div>
      <div className={styles.listingCarousel}>
        {renderExclusiveTag()}
        <CarouselImage
          images={images}
          fixedImageSize
          onImageChange={(img) => setMainImageUrl(img.url)}
          imageClassName={styles.listCardImgContainer}
          navButtonsAlwaysVisible={!isMobile}
          navButtonsAlwaysInvisible={isMobile}
          indicatorAlwaysVisible={true}
          imageCountNoIndicators={1}
          cycleNavigation={false}
          height={isMapPreview ? pinPreviewCarouselHeight : listingCardCarouselHeight}
          NextIcon={
            <BaseIcon
              iconFileName="arrowRightDarkIcon"
              className={styles.navIcon}
              iconAlt="next"
              iconSize={20}
              onClick={preventGoToDetailPage}
            />
          }
          PrevIcon={
            <BaseIcon
              iconFileName="arrowLeftDarkIcon"
              className={styles.navIcon}
              iconAlt="previous"
              iconSize={20}
              onClick={preventGoToDetailPage}
            />
          }
          navButtonsClassName={cn(styles.carouselNavigationButton, {
            [styles.isVisible]: true,
          })}
          indicatorIconButtonProps={{
            className: styles.indicatorButton,
          }}
          IndicatorIcon={
            <FiberManualRecordIcon
              className={styles.carouselIndicator}
              onClick={preventGoToDetailPage}
            />
          }
          activeIndicatorIconButtonProps={{
            className: styles.indicatorActiveClassName,
          }}
          navButtonsWrapperProps={{
            className: styles.carouselNavigationButtonWrapper,
          }}
        />
      </div>

      <div>
        <div className={cn(styles.listCardDataContainer, styles.listCardDataDetails)}>
          <div
            className={cn(
              styles.listCardDataDetailsRow,
              styles.listCardExtraDetailsGroup,
              styles.listCardLocation,
            )}
          >
            <Typography
              color={color}
              variant="subtitle2"
              component="p"
              className={styles.listCardDataDetailsType}
            >
              {SearchLogic.getNeighboorhoodForDisplay(location, item.regions, item.dataType) ||
                SearchLogic.getAddressForDisplay(location)}
            </Typography>
          </div>
          <div className={styles.listCardDataDetailsRow}>
            <Typography
              color={color}
              variant="h4"
              component="p"
              className={cn(styles.listCardTextLine, styles.listCardTitle)}
            >
              {item?.title}
            </Typography>
          </div>
          <Divider className={styles.listCardBreakLine} />
          <div className={styles.listCardExtraDetails}>
            <div className={styles.listCardExtraDetailsGroup}>
              {item.dataType === "venue" && (
                <Image
                  src={`${SVG_URL}/venueListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="venue card icon"
                />
              )}
              {item.dataType === "vendor" && (
                <Image
                  src={`${SVG_URL}/vendorListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="vendor card icon"
                />
              )}
              <Typography
                color={color}
                variant="subtitle2"
                component="p"
                className={cn(styles.listCardTextLine, styles.listCardCapitalize)}
              >
                {item.dataType}
              </Typography>
            </div>
            {(item.capacity?.max ?? 0) > 0 && (
              <div className={cn(styles.listCardExtraDetailsGroup)}>
                <Image
                  src={`${SVG_URL}/profileListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="profile card icon"
                />
                <Typography
                  color={color}
                  variant="subtitle2"
                  component="p"
                  className={styles.listCardTextLine}
                >
                  {NumberHelper.withCommas(`${item.capacity?.max}`)}
                </Typography>
              </div>
            )}
            {item.dataType === "vendor" && (item.categories ?? []).length > 0 && (
              <div className={cn(styles.listCardExtraDetailsGroup)}>
                <Image
                  src={`${SVG_URL}/videoListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="Vendor Category Icon"
                />
                <Typography
                  color={color}
                  variant="subtitle2"
                  component="p"
                  className={cn(styles.listCardTextLine, styles.listCardCapitalize)}
                >
                  {SearchLogic.translateCategories(item.categories)?.[0]}
                </Typography>
              </div>
            )}
            <div className={styles.listCardExtraDetailsGroup}>
              <Image
                src={`${SVG_URL}/walletListingCardIconGrey.svg`}
                height="16"
                width="16"
                alt="wallet card icon"
              />
              <Typography
                color={color}
                variant="subtitle2"
                component="p"
                className={styles.listCardTextLine}
                sx={{
                  paddingLeft: "2px",
                }}
              >
                {SearchLogic.formatSearchPricing(item)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingCardInnerV2;
