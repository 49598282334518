export function ReachedLimitWarningSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#FFF1F1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9974 13.3334C16.3155 13.3334 13.3307 16.3182 13.3307 20.0001C13.3307 23.682 16.3155 26.6667 19.9974 26.6667C23.6793 26.6667 26.6641 23.682 26.6641 20.0001C26.6641 16.3182 23.6793 13.3334 19.9974 13.3334ZM11.6641 20.0001C11.6641 15.3977 15.395 11.6667 19.9974 11.6667C24.5998 11.6667 28.3307 15.3977 28.3307 20.0001C28.3307 24.6025 24.5998 28.3334 19.9974 28.3334C15.395 28.3334 11.6641 24.6025 11.6641 20.0001ZM19.9974 15.8334C20.4576 15.8334 20.8307 16.2065 20.8307 16.6667V20.8334C20.8307 21.2937 20.4576 21.6667 19.9974 21.6667C19.5372 21.6667 19.1641 21.2937 19.1641 20.8334V16.6667C19.1641 16.2065 19.5372 15.8334 19.9974 15.8334ZM20.8307 23.7501C20.8307 23.2898 20.4576 22.9167 19.9974 22.9167C19.5372 22.9167 19.1641 23.2898 19.1641 23.7501V24.1667C19.1641 24.627 19.5372 25.0001 19.9974 25.0001C20.4576 25.0001 20.8307 24.627 20.8307 24.1667V23.7501Z"
        fill="#EA0000"
      />
    </svg>
  );
}
